<template>
  <div class="user-coupon">
    <div class="coupon-rule">
      <van-icon name="question-o" @click="goRule" />
    </div>
    <van-tabs v-model="active" color="#FF7226" title-active-color="#FF7226" background="#fff" @change="tabChange">
      <van-tab name="1" :title="`可用(${usable})`" />
      <van-tab name="2" :title="`使用记录(${usageRecord})`" />
      <van-tab name="3" :title="`过期(${overdue})`" />
    </van-tabs>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length > 0 ? '到底了，没有券了~' : ''"
        @load="getList"
      >
        <div v-if="list.length > 0 || firstLoading" class="list">
          <div class="box">
            <div v-for="item in list" :key="item.id" class="item" :class="{ used: params.type == 2, out: params.type == 3 }">
              <div class="head">
                <div class="city">{{ item.cityName }}</div>
                <template v-if="item.couponsType == 1">
                  <div class="num"><span>{{ (item.percentOff / 10).toFixed(1) }}折</span></div>
                  <div class="tip"><span>折扣券</span></div>
                </template>
                <template v-else>
                  <div class="num">￥<span>{{ item.priceOff * 1 / 100 }}</span></div>
                  <div class="tip"><span>抵扣券</span></div>
                </template>
              </div>
              <div class="cont">
                <div class="title">{{ item.couponsTitle }}{{ item.couponsType == 1 ? '折扣券' : '抵扣券' }}</div>
                <div class="des">服务费满{{ item.priceAvailable / 100 }}元时可用</div>
                <div class="bottom">
                  <div class="date">有效期至 : {{ item.validEndTime | parseTime }}</div>
                  <div class="per" @click="goDetail(item)">使用规则</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="emprty">
          <DefaultPage />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { groupCount, getCouponList } from '@/api/user'
import DefaultPage from '@/components/DefaultPage'
import { parseTime } from '@/utils/index'
export default {
  components: { DefaultPage },
  filters: {
    parseTime(val) {
      return parseTime(val, '{y}-{m}-{d}')
    }
  },
  data() {
    return {
      active: 1,
      overdue: 0,
      usable: 0,
      usageRecord: 0,
      list: [],
      params: { limit: 20, page: 1, type: 1, createTimeSort: 2 },
      count: 0,
      loading: false,
      finished: false,
      refreshing: false,
      firstLoading: true
    }
  },
  mounted() {
    this.groupCount()
  },
  methods: {
    tabChange() {
      this.params.type = this.active
      this.list = []
      this.firstLoading = true
      this.onRefresh()
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.params.page = 1
      this.getList()
    },
    goRule() {
      this.$router.push({ name: 'userCouponRule' })
    },
    goDetail(item) {
      this.$router.push({ name: 'userCouponDetail', params: { details: item }})
    },
    async groupCount() {
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await groupCount()
      this.overdue = res.datas.overdue
      this.usable = res.datas.usable
      this.usageRecord = res.datas.usageRecord
      loading.clear()
      console.log(res)
    },
    async getList() {
      const res = await getCouponList(this.params)
      if (this.refreshing) {
        this.list = []
        this.page = 1
        this.refreshing = false
      }
      const list = res.datas && res.datas.data || []
      for (let i = 0; i < list.length; i++) {
        const item = { ...list[i] }
        this.list.push(item)
      }
      this.loading = false
      const count = res.datas && res.datas.count
      this.count = count
      if (this.list.length >= count * 1) {
        this.finished = true
      }
      this.params.page += 1
      this.firstLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.user-coupon::v-deep {
  position: relative;
  padding-top: 10px;
  .coupon-rule {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #999999;
    font-size: 18px;
    z-index: 999;
  }
  .van-tabs__wrap {
    box-shadow: 0px 1px 1px rgba(0,0,0,0.06);
  }
  .list {
    padding-top: 10px;
    .box {
      padding: 0 10px 10px 10px;
    }
    .item {
      display: flex;
      width: 361px;
      height: 122px;
      background-image:url(~@/assets/user/coupon@2x.png);
      background-size: 100%;
      &.used {
        background-image: url(~@/assets/user/coupon_use@2x.png);
      }
      &.out {
        background-image: url(~@/assets/user/coupon_out@2x.png);
        .cont {
          .title {
            color: #CCCCCC;
          }
          .des {
            color: #CCCCCC;
          }
          .bottom {
            .date {
              color: #CCCCCC;
            }
            .per {
              color: #CCCCCC;
            }
          }
        }
      }
      .head {
        width: 106px;
        padding: 16px 0 0 9px;
        color: #fff;
        .city {
          padding-left: 10px;
        }
        .num {
          padding-top: 10px;
          text-align: center;
          span {
            font-size: 24px;
          }
        }
        .tip {
          display: flex;
          justify-content: center;
          span {
            margin-top: 10px;
            border: 1px solid #fff;
            font-size: 10px;
            padding: 4px 8px;
            border-radius: 16px;
          }
        }
      }
      .cont {
        width: 200px;
        padding: 20px 0 0 16px;
        .title {
          font-size: 16px;
        }
        .des {
          padding-top: 8px;
          height: 38px;
          font-size: 12px;
          color: #FF7700;
        }
        .bottom {
          display: flex;
          font-size: 12px;
          .date {
            flex: 1;
            color: #999999;
          }
          .per {
            color: #2697FF;
          }
        }
      }
    }
  }
}
</style>
